import styles from "../../../styles/news.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../../components/layout/layout"
import SEO from "../../../components/seo"
import React from "react"
import NewsCard from "../../../components/news-card/news-card"
import withAppContext from "../../../contexts/app.context"

const NewsPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      news: allMarkdownRemark(
        filter: {
          fields: { category: { eq: "news" } }
          frontmatter: { draft: { in: [false, null] } }
        }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            frontmatter {
              title
              shortTitle
              channel
              date(formatString: "MMM DD YYYY")
              draft
              excerpt
              externalUrl
              slug
            }
          }
        }
      }
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "company__news" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  const {
    news: { edges },
  } = queryResult

  return (
    <Layout>
      <div className={`container ${styles.newsroom}`}>
        <SEO
          title={metaTitle}
          pageUrl={"/company/news"}
          description={metaDescription}
          keywords={metaKeywordList}
          imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        />

        <h1 className="text--main font-weight-medium">Newsroom</h1>

        <div className={styles.newsroom__list}>
          {edges.map(res => (
            <div key={res.node.frontmatter.title}>
              <NewsCard
                date={res.node.frontmatter.date}
                channel={res.node.frontmatter.channel}
                title={
                  res.node.frontmatter.shortTitle || res.node.frontmatter.title
                }
                summary={res.node.frontmatter.excerpt}
                externalUrl={res.node.frontmatter.externalUrl}
                slug={res.node.frontmatter.slug}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default withAppContext(NewsPage)
