import "./news-card.scss"
import styles from "./news-card.module.scss"
import React from "react"
import { Link } from "gatsby"
import ArrowSvg from "../svg/arrow"

const NewsCard = ({ date, channel, title, summary, externalUrl, slug }) => {
  const ShareComponent = !!slug ? Link : "a"
  const shareProps = !!slug
    ? {
        to: `/company/news/${slug}`,
      }
    : {
        target: "_blank",
        rel: "noopener noreferrer",
        href: externalUrl,
      }

  return (
    <ShareComponent
      aria-label="Read More"
      className={styles.outerLink}
      {...shareProps}>
      <div className="news__wrapper">
        <div className="news">
          <div className="news__header">
            <span className="mr-3">{date}</span>|
            <span className="ml-3">{channel}</span>
          </div>
          <p className="news__title">{title}</p>
          <div className="news__description">
            <p className="news__description-text">{summary}</p>
          </div>
          <p className="news__faux-link">
            Read more
            <ArrowSvg />
          </p>
        </div>
      </div>
    </ShareComponent>
  )
}

export default NewsCard
